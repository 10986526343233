<template>
    <div>
        <div class="section-footer">
            <div class="footer-top">
                <div class="row marginBottom1o7">
                    <!--底部nav-->
                    <div class="part-nav">
                        <div class="list-nav">
                            <h2>产品中心</h2>
                            <h3>
                                <a href="/prductlist" target="_blank">产品推荐</a>
                            </h3>
                            <h3>
                                <a href="/prductlist" target="_blank">最新产品</a>
                            </h3>

                        </div>
                        <div class="list-nav">
                            <h2>关于企顺</h2>
                            <h3>
                                <a href="/about" target="_blank">关于企顺</a>
                            </h3>
                            <h3>
                                <a href="/news" target="_blank">新闻中心</a>
                            </h3>
                            <h3>
                                <a href="/society" target="_blank">社会责任</a>
                            </h3>
                            <h3>
                                <a href="/csr" target="_blank">CSR声明</a>
                            </h3>
                            <h3>
                                <a href="/connect" target="_blank">联系我们</a>
                            </h3>
                        </div>
                        <div class="list-nav">
                            <h2>员工关怀</h2>
                            <h3>
                                <a href="/employee" target="_blank">员工关怀</a>
                            </h3>
                            <h3>
                                <a href="/joinus" target="_blank">加入我们</a>
                            </h3>
                        </div>
                        <div class="list-nav">
                            <h2>友情链接</h2>
                            <h3><a href="/" target="_blank">企顺商城</a></h3>

                        </div>
                        <div class="list-nav">
                            <h2>企顺商城</h2>

                        </div>

                    </div>
                    <!-- icon -->
                    <div class="top-right">
                        <!-- <img src="@/assets/img/logo.png" class="bottomBOYA" alt=""> -->

                        <div class="box-top">
                           <div class="box-top-1"> 
                            
                                深圳市企顺创新科技发展有限公司
                           
                           </div>
                           <div class="box-top-2"> 
                            <img src="@/assets/bottom/email.png" style="width: 1vw;height: 0.7vw;margin-right: 0.5vw;" >
                            <div>
                                邮箱：HR@keesion.com
                            </div>
                           </div>
                           <div class="box-top-2"> 
                            <img src="@/assets/bottom/post.png" style="width: 1vw;height: 0.7vw;margin-right: 0.5vw;" >
                            <div>
                                邮编：518110
                            </div>
                        
                        </div>
                           <div class="box-top-2">
                            <img src="@/assets/bottom/addr.png" style="width: 0.7vw;height: 0.7vw;margin-right: 0.5vw;margin-left: 0.1vw;" >
                            <div>
                                地址：广东省深圳市龙华区民治街道融创智汇大厦A座1906
                            </div>
                           </div>
                        </div>

                        <div class="box-media">

                            <div class="item-container" :style="{
                                    backgroundImage: `url('${wx}')`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize:'1.2vw 1.2vw',
                                    backgroundPosition: 'center'
                                }">
                                <div class="item-container-i" style="position: absolute;top:-6vw;left:-2vw">
                                    <img src="@/assets/bottom/wxqr.png" />
                                </div>
                            </div>
                            <div class="item-container" :style="{
                                    backgroundImage: `url('${wb}')`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize:'1.2vw 1.2vw',
                                    backgroundPosition: 'center'
                                }">
                                <div class="item-container-i" style="position: absolute;top:-6vw;left:-2vw">
                                    <img src="@/assets/bottom/wbqr.png" />
                                </div>
                            </div>
                            <div class="item-container" :style="{
                                    backgroundImage: `url('${dy}')`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize:'1.2vw 1.2vw',
                                    backgroundPosition: 'center'
                                }">
                                <div class="item-container-i" style="position: absolute;top:-6vw;left:-2vw">
                                    <img src="@/assets/bottom/dyqr.png" />
                                </div>
                            </div>
                            <div class="item-container" :style="{
                                    backgroundImage: `url('${xhs}')`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize:'1.2vw 0.5vw',
                                    backgroundPosition: 'center'
                                }">
                                <div class="item-container-i" style="position: absolute;top:-6vw;left:-2vw">
                                    <img src="@/assets/bottom/xhsqr.png" />
                                </div>
                            </div>

                            <!-- <div class="item">
                                <img src="@/assets/img/qr.png" />
                                <div>企顺抖音旗舰店二维码</div>
                            </div>
                            <div class="item">
                                <img src="@/assets/img/qr.png" />
                                <div>企顺公众号二维码</div>
                            </div>
                            <div class="item">
                                <img src="@/assets/img/qr.png" />
                                <div>企顺微博公众号</div>
                            </div>
                            <div class="item">
                                <img src="@/assets/img/qr.png" />
                                <div>企顺小红书账号二维码</div>
                            </div> -->



                        </div>
                    </div>
                </div>

            </div>


            <div class="footer-bottom">
                <div class="left">
                    <a href="/security" target="_blank">安全代码</a>
                    <a href="/hotline" target="_blank">服务热线</a>
                    <a href="/question" target="_blank">常见问题</a>
                    <a href="/download" target="_blank">下载中心</a>
                </div>

                <div class="right">
                    <a href="#" target="_blank">Copyright © 2024 Keesion企顺创新</a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "FooterComp",
    data(){
        return {
            wx:require('@/assets/bottom/wx.png'),
            wb:require('@/assets/bottom/wb.png'),
            dy:require('@/assets/bottom/dy.png'),
            xhs:require('@/assets/bottom/xhs.png'),
        }
    }
}


</script>

<style lang="scss" scoped>
@media (max-width:768px) {
    .section-footer {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding-top: 1rem;

        .footer-top {
            display: flex;
            flex-direction: column;
            padding: 0 11.97916vw;
            box-sizing: border-box;
            padding-bottom: 1.09375vw;

            .row {
                width: 100%;
                display: flex;
                flex-direction: row;
                position: relative;
                justify-content: space-between;
                position: relative;

                .part-nav {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;

                    .list-nav {
                        display: flex;
                        flex-direction: column;
                        margin-right: 4.01041vw;

                        h2 {
                            color: #000;
                            font-size: 0.8rem;
                            font-weight: bold;
                        }

                        h3 {
                            margin-top: 0.5rem;
                            margin-bottom: 0.5rem;

                            a {
                                color: #a3a3a3;
                                cursor: pointer;
                                font-size: 0.5rem;
                            }
                        }
                    }
                }

                .top-right {
                    display: none;
                }

                .bottomBOYA {
                    width: 10.47916vw;
                    height: 2.19791vw;
                    position: absolute;
                    right: 0;
                    /* top: -0.3125vw; */
                }

                .box-media {
                    position: absolute;
                    right: 1.5vw;
                    bottom: 0.3125vw;
                    display: flex;
                    flex-direction: row;

                    img {
                        width: 7vw;
                        height: 7vw;
                    }
                }
            }
        }

        .footer-bottom {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            padding: 0 11.97916vw;
            border-top: 1px solid #ddd;
            box-sizing: border-box;
            justify-content: space-between;

            .right {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 0.5rem;
                position: relative;

                a {
                    color: #a3a3a3;
                    font-size: 0.5rem;
                    margin-right: 2.08333vw;
                    padding-top: 1.05vw;
                    padding-bottom: 1.2vw;
                }

                .friend-links {
                    color: #a3a3a3;
                    cursor: pointer;
                    padding-top: 1.05vw;
                    padding-bottom: 1.2vw;
                    position: relative;


                }

                .links {
                    /* width: 5.46161vw; */
                    /* background: url(../icon/links-img.png); */
                    background-color: #fff;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                    border-radius: 0.5vw;
                    background-size: 100% 100%;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 3vw;
                    display: none;
                    flex-direction: column;
                    justify-content: space-between;
                    z-index: 999;

                    .links-box {
                        overflow-y: auto;
                        /* width: 5.16161vw; */
                        max-height: 8vw;
                        /* margin-right: 0.26041vw; */
                        position: relative;
                        z-index: 1000;
                        overscroll-behavior: contain;
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            .left {
                font-size: 0.625vw;
                color: #a3a3a3;
            }
        }
    }
}

@media (min-width:768px) {
    .section-footer {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: #000000;
        padding-top: 3.85416vw;
        color: white;

        .footer-top {
            display: flex;
            flex-direction: column;
            padding: 0 11.97916vw;
            box-sizing: border-box;
            padding-bottom: 1.09375vw;

            .row {
                width: 100%;
                display: flex;
                flex-direction: row;
                position: relative;
                justify-content: space-between;
                position: relative;

                .part-nav {
                    display: flex;
                    flex-direction: row;

                    .list-nav {
                        display: flex;
                        flex-direction: column;
                        margin-right: 4.01041vw;

                        h2 {
                            // color: #000;
                            font-size: 0.9375vw;
                            margin-bottom: 0.92708vw;
                            font-weight: bold;
                        }

                        h3 {
                            margin-bottom: 0.075vw;
                            margin-top: 0.8vw;
                            // margin: 0;

                            a {
                                color: white;
                                cursor: pointer;
                                font-size: 0.72916vw;
                            }
                        }
                    }
                }

                .top-right {
                    display: flex;

                    .box-top{
                        margin-top: 1vw;
                        .box-top-1{
                            font-size: 0.9375vw;
                            font-weight: bold;
                            margin-bottom: 2vw;
                        }

                        .box-top-2{
                            display: flex;
                            align-items: center;
                            font-size: 0.72916vw;
                            margin-bottom: 1vw;
                        }
                    }
                }

                .bottomBOYA {
                    width: 10.47916vw;
                    height: 2.19791vw;
                    position: absolute;
                    right: 0;
                    /* top: -0.3125vw; */
                }

                .box-media {
                    position: absolute;
                    right: 8vw;
                    bottom: 0.3125vw;
                    display: flex;
                    flex-direction: row;

                    .item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-right: 1rem;
                        width: 6vw;
                        text-align: center;
                        font-size: 0.8rem;

                        div {
                            padding: 0 0.3rem;
                        }
                    }

                    img {
                        width: 6vw;
                        height: 6vw;
                    }

                    .item-container {
                        cursor: pointer;
                        width: 2vw;
                        height: 2vw;
                        background-color: #5187f7;
                        margin-left: 1vw;
                        position: relative;
                        border-radius: 1vw;

                        .item-container-i{
                            display: none;
                        }
                    }

                    

                    .item-container:hover{
                        background-color: #286bf7;

                        .item-container-i{
                            display: block;
                        }
                    }

                    


                }
            }
        }

        .footer-bottom {
            width: 100vw;
            display: flex;
            align-items: center;
            position: relative;
            border-top: 2px solid #ddd;
            box-sizing: border-box;
            padding: 0 12vw;
            justify-content: space-between;

            .left {
                font-size: 0.72916vw;
                color: white;
                box-sizing: border-box;
                a {
                    color: white;
                    font-size: 0.72916vw;
                    margin-right: 2.08333vw;
                    // padding-top: 1.05vw;
                    // padding-bottom: 1.2vw;
                }
            }

            .right {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 0.72916vw;
                position: relative;
                box-sizing: border-box;
                a {
                    color: white;
                    font-size: 0.72916vw;
                    margin-right: 2.08333vw;
                    padding-top: 1.05vw;
                    padding-bottom: 1.2vw;
                }
            }

        
        }
    }
}
</style>