var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section-footer"},[_c('div',{staticClass:"footer-top"},[_c('div',{staticClass:"row marginBottom1o7"},[_vm._m(0),_c('div',{staticClass:"top-right"},[_vm._m(1),_c('div',{staticClass:"box-media"},[_c('div',{staticClass:"item-container",style:({
                                backgroundImage: `url('${_vm.wx}')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize:'1.2vw 1.2vw',
                                backgroundPosition: 'center'
                            })},[_vm._m(2)]),_c('div',{staticClass:"item-container",style:({
                                backgroundImage: `url('${_vm.wb}')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize:'1.2vw 1.2vw',
                                backgroundPosition: 'center'
                            })},[_vm._m(3)]),_c('div',{staticClass:"item-container",style:({
                                backgroundImage: `url('${_vm.dy}')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize:'1.2vw 1.2vw',
                                backgroundPosition: 'center'
                            })},[_vm._m(4)]),_c('div',{staticClass:"item-container",style:({
                                backgroundImage: `url('${_vm.xhs}')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize:'1.2vw 0.5vw',
                                backgroundPosition: 'center'
                            })},[_vm._m(5)])])])])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part-nav"},[_c('div',{staticClass:"list-nav"},[_c('h2',[_vm._v("产品中心")]),_c('h3',[_c('a',{attrs:{"href":"/prductlist","target":"_blank"}},[_vm._v("产品推荐")])]),_c('h3',[_c('a',{attrs:{"href":"/prductlist","target":"_blank"}},[_vm._v("最新产品")])])]),_c('div',{staticClass:"list-nav"},[_c('h2',[_vm._v("关于企顺")]),_c('h3',[_c('a',{attrs:{"href":"/about","target":"_blank"}},[_vm._v("关于企顺")])]),_c('h3',[_c('a',{attrs:{"href":"/news","target":"_blank"}},[_vm._v("新闻中心")])]),_c('h3',[_c('a',{attrs:{"href":"/society","target":"_blank"}},[_vm._v("社会责任")])]),_c('h3',[_c('a',{attrs:{"href":"/csr","target":"_blank"}},[_vm._v("CSR声明")])]),_c('h3',[_c('a',{attrs:{"href":"/connect","target":"_blank"}},[_vm._v("联系我们")])])]),_c('div',{staticClass:"list-nav"},[_c('h2',[_vm._v("员工关怀")]),_c('h3',[_c('a',{attrs:{"href":"/employee","target":"_blank"}},[_vm._v("员工关怀")])]),_c('h3',[_c('a',{attrs:{"href":"/joinus","target":"_blank"}},[_vm._v("加入我们")])])]),_c('div',{staticClass:"list-nav"},[_c('h2',[_vm._v("友情链接")]),_c('h3',[_c('a',{attrs:{"href":"/","target":"_blank"}},[_vm._v("企顺商城")])])]),_c('div',{staticClass:"list-nav"},[_c('h2',[_vm._v("企顺商城")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-top"},[_c('div',{staticClass:"box-top-1"},[_vm._v(" 深圳市企顺创新科技发展有限公司 ")]),_c('div',{staticClass:"box-top-2"},[_c('img',{staticStyle:{"width":"1vw","height":"0.7vw","margin-right":"0.5vw"},attrs:{"src":require("@/assets/bottom/email.png")}}),_c('div',[_vm._v(" 邮箱：HR@keesion.com ")])]),_c('div',{staticClass:"box-top-2"},[_c('img',{staticStyle:{"width":"1vw","height":"0.7vw","margin-right":"0.5vw"},attrs:{"src":require("@/assets/bottom/post.png")}}),_c('div',[_vm._v(" 邮编：518110 ")])]),_c('div',{staticClass:"box-top-2"},[_c('img',{staticStyle:{"width":"0.7vw","height":"0.7vw","margin-right":"0.5vw","margin-left":"0.1vw"},attrs:{"src":require("@/assets/bottom/addr.png")}}),_c('div',[_vm._v(" 地址：广东省深圳市龙华区民治街道融创智汇大厦A座1906 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-container-i",staticStyle:{"position":"absolute","top":"-6vw","left":"-2vw"}},[_c('img',{attrs:{"src":require("@/assets/bottom/wxqr.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-container-i",staticStyle:{"position":"absolute","top":"-6vw","left":"-2vw"}},[_c('img',{attrs:{"src":require("@/assets/bottom/wbqr.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-container-i",staticStyle:{"position":"absolute","top":"-6vw","left":"-2vw"}},[_c('img',{attrs:{"src":require("@/assets/bottom/dyqr.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-container-i",staticStyle:{"position":"absolute","top":"-6vw","left":"-2vw"}},[_c('img',{attrs:{"src":require("@/assets/bottom/xhsqr.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"left"},[_c('a',{attrs:{"href":"/security","target":"_blank"}},[_vm._v("安全代码")]),_c('a',{attrs:{"href":"/hotline","target":"_blank"}},[_vm._v("服务热线")]),_c('a',{attrs:{"href":"/question","target":"_blank"}},[_vm._v("常见问题")]),_c('a',{attrs:{"href":"/download","target":"_blank"}},[_vm._v("下载中心")])]),_c('div',{staticClass:"right"},[_c('a',{attrs:{"href":"#","target":"_blank"}},[_vm._v("Copyright © 2024 Keesion企顺创新")])])])
}]

export { render, staticRenderFns }